module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-netlify-cms@7.8.0_gatsby@5.8.1_netlify-cms-app@2.9.7_react-dom@18.2.0_react@18.2.0_webpack@5.77.0/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.1_@chakra-ui+react@2.5.5_gatsby@5.8.1/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isBaseProvider":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.8.0_gatsby-plugin-sharp@5.8.1_gatsby@5.8.1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.8.1_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.0.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
